import React from "react";
import "./shareWithPharmacyBtn.scss";
import { Reply } from "@material-ui/icons";

const ShareWithPharmacyBtn = (props) => {
  const { handleSharePharmacyBtn } = props;

  // Display UI ===>

  const displayShareBtn = () => {
    return (
      <div className="share-with-pharmacy-btn" onClick={handleSharePharmacyBtn}>
        <i className="share-with-pharmacy-icons">
          <Reply
            fontSize="small"
            style={{
              transform: "scaleX(-1)",
            }}
          />
        </i>
        Share With Pharmacy
      </div>
    );
  };

  // <===

  return (
    <div className="share-with-pharmacy-btn-container">{displayShareBtn()}</div>
  );
};

export default ShareWithPharmacyBtn;
