import React from "react";
import "./staffAlertMessagePopup.scss";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

const StaffAlertMessagePopup = (props) => {
  const { alertMsg, handleClose } = props;

  return (
    <div
      className={
        "staff-message-toaster " +
        (alertMsg?.type === "error"
          ? "staff-message-toaster-error"
          : "staff-message-toaster-success")
      }
    >
      <div className="smt-message-content">
        {alertMsg?.title && <p className="smt-title">{alertMsg?.title}</p>}
        {alertMsg?.content && (
          <p
            className="smt-content"
            dangerouslySetInnerHTML={alertMsg?.content}
          />
        )}
      </div>
      <div className="smt-close-div">
        <CloseRoundedIcon onClick={handleClose} className="smt-close" />
      </div>
    </div>
  );
};

export default StaffAlertMessagePopup;
