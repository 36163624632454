/**
 * @function : ERxList
 * @description : To show the list of ERx uploaded by doctor in ongoing consultation screen and on
 *                editing the prescription screen.
 */
import React, { useState, useCallback, useEffect } from "react";
import afterLoginApiAxios from "../../Util/API Calls/afterLogin";
import axios from "axios";
import DocumentContainer from "../UI Component/DocumentViewer/DocumentCotainer";
import ShareManualRxModal from "./ShareManualRxMoadal";
import ErxCell from "./ErxCell";
import { getSharedPrescriptionResponse } from "../PrescriptionTabComponent/PrescriptionTabAPIHandler";
import Loader from "../UI Component/Loader/Loader";
import { LOCALSTORAGEKEY } from "../../Util/Helper/localStorageUtils";
import { C2CConsole } from "../../Util/Helper/C2CConsole";
import ShareWithPharmacyBtn from "../ERXViewContainer/common/ShareWithPharmacyBtn";
import ShareWithPharmacyPopup from "../ERXViewContainer/common/ShareWithPharmacyPopup";
import StaffAlertMessagePopup from "../common/StaffAlertMessgePopup";
import { c2cGetApiHandler, c2cPostApiHandler } from "../../Util/c2cApiHandler";
import {
  GET_PHARMACY_LIST,
  GET_USER_SETTINGS,
  SHARE_RX_WITH_PHARMACY,
  UPDATE_USER_SETTINGS,
} from "../ERXViewContainer/ERXViewApiHandler/apiConstants";

const ERxList = (props) => {
  const [e_rx_list, setERxList] = useState([]);
  const [hrsMinSec, sethrsMinSec] = useState([]);
  const [saved_e_rx_list, setSavedErxList] = useState([]);
  const { appointment_id, erx_counter } = props;
  const [showRxModal, setshowRxModal] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");
  const [documentShow, setDocumentShow] = useState(false);
  const [fullView, setFullView] = useState(false);
  const is_staff = localStorage.getItem("user_type");
  const [showLoader, setShowLoader] = useState(false);
  const [showSharePopUp, setShowSharePopUp] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [pharmacyList, setPharmacyList] = useState([]);
  const [isAnyPharmacySelected, setIsAnyPharmacySelected] = useState(false);
  const [documentDetils, setDocumentDetails] = useState({
    documentID: "",
    erxType: "",
  });
  const [selectedPharmacyList, setSelectedPharmacyList] = useState([]);

  const userId = localStorage.getItem(LOCALSTORAGEKEY.USER_ID);
  const staffId = localStorage.getItem(LOCALSTORAGEKEY.STAFF_ID);

  const clinicId = localStorage.getItem(LOCALSTORAGEKEY.CLINIC_ID);

  const ImagetoPrint = (source) => {
    return (
      "<html><head><scri" +
      "pt>function step1(){\n" +
      "setTimeout('step2()', 10);}\n" +
      "function step2(){window.print();window.close()}\n" +
      "</scri" +
      "pt></head><body onload='step1()'>\n" +
      "<img src='" +
      source +
      "' /></body></html>"
    );
  };

  const printFile = (url, fileType) => {
    if (fileType.toLowerCase().includes("pdf")) {
      window.open(url, "PRINT", "height=400,width=600");
    }
    if (
      fileType.toLowerCase().includes("jpg") ||
      fileType.toLowerCase().includes("png") ||
      fileType.toLowerCase().includes("jpeg")
    ) {
      var Pagelink = "about:blank";
      var pwa = window.open(Pagelink, "_new");
      pwa.document.open();
      pwa.document.write(ImagetoPrint(url));
      pwa.document.close();
    }
  };

  useEffect(() => {
    getPharmacyList();
  }, [erx_counter]);

  useEffect(() => {
    const anySelected = pharmacyList.some((pharmacy) => pharmacy.selected);
    setIsAnyPharmacySelected(anySelected);
  }, [pharmacyList]);

  useEffect(() => {
    const updatedPharmacyList = pharmacyList.map((pharmacy) => {
      const matchingSelectedPharmacy = selectedPharmacyList.find(
        (selected) => selected.pharmacy_id === pharmacy.pharmacy_id
      );

      return {
        ...pharmacy,
        selected: matchingSelectedPharmacy
          ? matchingSelectedPharmacy.selected
          : false,
      };
    });
    setPharmacyList(updatedPharmacyList);
  }, [selectedPharmacyList]);

  useEffect(() => {
    getSharedEPrescriptions();
    getSavedEPrescriptions();
  }, [erx_counter]);

  const getSharedEPrescriptions = async () => {
    await afterLoginApiAxios
      .post(
        "doctor/get-shared-e-prescriptions",
        {
          "authorizer-principal-id": {
            user_type: "1",
            user_id: props?.appointmentData?.doctor_id
              ? props?.appointmentData?.doctor_id
              : userId,
          },
          user_id: props?.appointmentData?.doctor_id
            ? props?.appointmentData?.doctor_id
            : userId,
          appointment_id: appointment_id, //"1b90c17c-f956-4570-9214-1132fec31893",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("id_token"),
          },
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          if (response.data.data && response.data.data.length > 0) {
            setERxList(response.data.data);
          } else {
            setERxList([]);
          }
        }
      })
      .catch((err) => C2CConsole.log(err));
  };
  const getSavedEPrescriptions = async () => {
    await afterLoginApiAxios
      .post(
        "/api/v1/doctor/get-saved-e-prescription",
        {
          "authorizer-principal-id": {
            user_type: "1",
            user_id: props?.appointmentData?.doctor_id
              ? props?.appointmentData?.doctor_id
              : userId,
          },
          user_id: props?.appointmentData?.doctor_id
            ? props?.appointmentData?.doctor_id
            : userId,
          appointment_id: appointment_id, //"1b90c17c-f956-4570-9214-1132fec31893",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("id_token"),
          },
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          if (response.data.data && response.data.data.length > 0) {
            setSavedErxList(response.data.data);
          } else {
            setSavedErxList([]);
          }
        }
      })
      .catch((err) => C2CConsole.log(err));
  };
  const downloadURI = async (url, name, fileType) => {
    const blob = await axios.get(url, {
      headers: {
        "Content-Type": "application/octet-stream",
      },
      responseType: "blob",
    });
    const a = document.createElement("a");
    const href = window.URL.createObjectURL(blob.data);
    a.href = href;
    a.download = name + "." + fileType.trim();
    a.click();
  };

  const ShareERx = (data) => {
    afterLoginApiAxios
      .post(
        "doctor/share-e-prescription",
        {
          user_id: is_staff === "staff" ? staffId : userId,
          appointment_id: data.appointment_id,
          prescription_id: data.prescription_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("id_token"),
          },
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          setshowRxModal(true);
        }
      })
      .catch((err) => C2CConsole.log(err));
  };

  function closeNotification() {
    setDocumentShow(false);
    setFileUrl("");
    setFileName("");
    setFileType("");
    setFullView(false);
  }
  const ShareSavedErx = async (result) => {
    let reqPayload = {
      appointment_id: result.appointment_id,
      prescription_id: result.prescription_id,
    };
    setShowLoader(true);
    await getSharedPrescriptionResponse(reqPayload).then(([res, error]) => {
      setShowLoader(false);
      if (!error) {
        let set = props?.setERXCounter();
        setshowRxModal(true);
      }
    });
  };

  const handleSharePharmacyBtn = () => {
    setShowSharePopUp(true);
  };

  const handleCloseSharePopup = () => {
    setShowSharePopUp(false);
  };

  const handleSharePharmacy = () => {
    setShowSharePopUp(false);
    updateSharePharmacySettings();
    updateShareErxWithPharmacy();
  };

  const handleDefaultAlertMessage = () => {
    setTimeout(() => {
      setAlertMessage({
        show: false,
        title: "",
        content: "",
        type: "",
      });
    }, 2000);
  };

  const handleSelectPharmacy = (item) => {
    setPharmacyList((prevList) => {
      const updatedList = prevList.map((pharmacy) =>
        pharmacy.pharmacy_id === item.pharmacy_id
          ? { ...pharmacy, selected: !pharmacy.selected }
          : pharmacy
      );
      return updatedList;
    });
  };

  const handleCloseAlertMsg = () => {
    setAlertMessage({
      show: false,
      title: "",
      content: "",
      type: "",
    });
  };

  const updateShareErxWithPharmacy = () => {
    const payload = {
      data: pharmacyList
        .filter((pharmacy) => pharmacy?.selected)
        .map((pharmacy) => ({
          pharmacy_id: pharmacy?.pharmacy_id,
          appointment_id: appointment_id,
          documents: [
            {
              id: documentDetils.documentID,
              prescription_type: documentDetils.erxType,
            },
          ],
        })),
    };
    c2cPostApiHandler(SHARE_RX_WITH_PHARMACY, payload).then(([res, error]) => {
      if (!error) {
        if (res?.data?.statusCode === 200) {
          setAlertMessage({
            show: true,
            title: "",
            content: {
              __html: `Patient’s prescription has been shared successfully with the pharmacy`,
            },

            type: "success",
          });
          handleDefaultAlertMessage();
        } else {
          setAlertMessage({
            show: false,
            title: "",
            content: {
              __html: "",
            },

            type: "success",
          });
        }
      }
    });
  };

  const updateSharePharmacySettings = () => {
    let payload = {
      user_id: userId,
      clinic_id: clinicId ? clinicId : userId,
      section_type: "SharedPharmacy",
      filter_options: pharmacyList,
      sort_options: {},
    };
    c2cPostApiHandler(UPDATE_USER_SETTINGS, payload).then(([res, error]) => {
      if (!error) {
        if (res?.data?.statusCode === 200) {
          C2CConsole.log(res);
        }
      }
    });
  };

  const getPharmacyList = () => {
    c2cGetApiHandler(GET_PHARMACY_LIST(clinicId)).then(([res, error]) => {
      if (res?.data?.statusCode === 200) {
        const transformedData = res?.data?.data?.map((item) => ({
          pharmacy_id: item.pharmacy_id,
          name: item.name,
          selected: false,
        }));
        setPharmacyList(transformedData);
        getPharmacySectionSettings();
      } else {
      }
    });
  };

  const getPharmacySectionSettings = () => {
    c2cGetApiHandler(
      GET_USER_SETTINGS +
        `?section_type=SharedPharmacy&clinic_id=${clinicId}&user_id=${userId}`
    ).then(([res, error]) => {
      if (!error) {
        if (res?.data?.statusCode === 200) {
          let selectedPharmacyData = res?.data?.data[0]?.filter_options;
          setSelectedPharmacyList([...selectedPharmacyData]);
        }
      }
    });
  };

  const getErxDetails = (details) => {
    setDocumentDetails({
      documentID: details?.prescription_id,
      erxType: "erx",
    });
  };

  const displayShareWithPharmacyBtnUI = () => {
    return (
      <>
        {pharmacyList.length > 0 && (
          <ShareWithPharmacyBtn
            handleSharePharmacyBtn={() => handleSharePharmacyBtn()}
          />
        )}
      </>
    );
  };

  return (
    <>
      {showLoader && <Loader />}
      {
        <div className="px-3">
          {saved_e_rx_list?.length > 0 ? (
            <>
              <div className="rx_subheading my-3">
                Saved E-Prescriptions ({saved_e_rx_list.length})
              </div>
              {saved_e_rx_list?.length > 0 ? (
                <ErxCell
                  key={"saved"}
                  e_rx_list={saved_e_rx_list}
                  setFileName={setFileName}
                  setFileType={setFileType}
                  setFileUrl={setFileUrl}
                  setDocumentShow={setDocumentShow}
                  downloadURI={downloadURI}
                  ShareSavedErx={ShareSavedErx}
                  prescription_id={props.prescription_id}
                  expiryTime={props.appointmentData.expiryTime}
                  icons={{
                    edit: is_staff === "staff" ? false : true,
                    view: true,
                    download: true,
                    share: true,
                  }}
                  editCallBack={props.onEditCallBack}
                  saved={true}
                  sethrsMinSec={sethrsMinSec}
                  displaySubContent={displayShareWithPharmacyBtnUI()}
                  getErxDetails={getErxDetails}
                />
              ) : null}
              {hrsMinSec[0] &&
                hrsMinSec[1] &&
                hrsMinSec[2] &&
                is_staff !== "staff" && (
                  <p className="warning-counter my-1">{`Edit Prescription Expires in: ${hrsMinSec[0]}:${hrsMinSec[1]}:${hrsMinSec[2]}`}</p>
                )}
            </>
          ) : is_staff === "staff" ? (
            <div className="rx_subheading my-3">
              Saved E-Prescriptions (
              {saved_e_rx_list?.length ? saved_e_rx_list?.length : 0})
            </div>
          ) : null}
          {e_rx_list.length > 0 ? (
            <>
              <div className="rx_subheading my-3">
                Shared E-Prescriptions ({e_rx_list.length})
              </div>
              {e_rx_list.length > 0 ? (
                <ErxCell
                  key={"shared"}
                  e_rx_list={e_rx_list}
                  setFileName={setFileName}
                  setFileType={setFileType}
                  setFileUrl={setFileUrl}
                  setDocumentShow={setDocumentShow}
                  downloadURI={downloadURI}
                  ShareERx={ShareERx}
                  icons={{
                    edit: false,
                    view: true,
                    download: true,
                    share: true,
                  }}
                  getErxDetails={getErxDetails}
                />
              ) : null}
            </>
          ) : is_staff === "staff" ? (
            <div className="rx_subheading my-3">
              Shared E-Prescriptions (
              {e_rx_list?.length ? e_rx_list?.length : 0})
            </div>
          ) : null}
        </div>
      }
      {documentShow && (
        <DocumentContainer
          cancelHandler={closeNotification}
          fullViewHandler={() => {
            setFullView(!fullView);
          }}
          url={fileUrl}
          fileName={fileName}
          fileType={fileType}
          // width={width || ""}
        />
      )}
      <ShareManualRxModal
        show={showRxModal}
        handleClose={() => {
          setshowRxModal(false);
        }}
      />
      <ShareWithPharmacyPopup
        openPopup={showSharePopUp}
        disableShareBtn={!isAnyPharmacySelected}
        pharmacyList={pharmacyList}
        handleClosePopup={handleCloseSharePopup}
        handleSelectPharmacy={handleSelectPharmacy}
        handleSharePharmacy={handleSharePharmacy}
      />
      {alertMessage.show && (
        <StaffAlertMessagePopup
          alertMsg={alertMessage}
          handleClose={handleCloseAlertMsg}
        />
      )}
    </>
  );
};

export default ERxList;
