import React, { useState } from "react";
import SlotsContainer from "../Slots";
import DateSelector from "./DateSelector.component";
import "./slot.scss";
import Popover from "react-popover";
import Info from "../../assets/info.png";
import DelayTimePicker from "../DelayMarking/DelayTimePicker";

const BookSchedule = ({
  doctorId,
  bookingFromFollowUpDashboard = false,
  onDateSelect,
  onSlotSelect,
  setStartTime,
  className,
  slotsClass,
  hideAnnotation,
  consultType,
  followUpEndDate,
  setConsultData,
  followUpInfo,
  bookFollowUp,
  fromCalender,
  calenderDate,
  track,
  selectedProcedure,
  procedureDetails,
  alternateNumber,
  doctor,
  retainedSlot,
  retainedSlotObj,
  apiConsultationType, //new add
  getSlotTime,
  handleSlotToEMpty,
  slugUrl,
  reschedule,
  handleInstruction,
  doctorSlug, //pasing doctor slug
  procedure_id, //passing procedure id for rescheduling
  type,
  selectedDateMeta,
  selectedClinic,
  clinicId, // passing for patient side rescheduling
  handleDate,
  selectedResourceProcedure,
  handleSelectResourceSlot,
  hasResourceAccess,
  addBooking,
  procedures,
  booking_type,
  isReschedule,
  isConsultant,
  isSchedule,
  isAbhaTokenBooking,
  showPremiumSlotSelection,
  setErrors,
  setTimer,
  errors,
  timer,
  onApplyingPremiumSlot,
  showAllotedPremiumSlot,
  allotedPremiumSlot,
  selectedProcedureList,
  setDuration,
  dashboardSelectedDate,
  setAllotedPremiumSlot,
  isLeadBooking,
  followUpBooking,
}) => {
  const [selectedDate, setSelectedDate] = useState({});
  const [dateSelectEnable, setDateSelectEnable] = useState(true);
  const [openPremiumPopover, setOpenPremiumPopover] = useState(false);

  const selectDateHandler = (date, clicked) => {
    if (dateSelectEnable) {
      setSelectedDate(date);
      onDateSelect(date);
      if (clicked && setAllotedPremiumSlot) {
        setAllotedPremiumSlot({
          startTime: "",
          endTime: "",
          slot_id: "",
        });
      }
    }
  };

  const handleHr = (e) => {
    let time = { ...timer };

    if (
      e.target.value <= 12 &&
      e.target.value >= 0 &&
      e.target.value.length <= 2
    ) {
      if (e.target.name === "start_time") time.start_hr = e.target.value;
      if (e.target.name === "end_time") time.end_hr = e.target.value;
      setTimer(time);
    }
  };
  const handleMin = (e) => {
    let time = { ...timer };
    if (
      e.target.value <= 59 &&
      e.target.value >= 0 &&
      e.target.value.length <= 2
    ) {
      if (e.target.name === "start_time") time.start_min = e.target.value;
      if (e.target.name === "end_time") time.end_min = e.target.value;
      setTimer(time);
    }
  };
  const handleMeridian = (e) => {
    let time = { ...timer };
    if (e.target.name === "start_time") time.start_meridian = e.target.value;
    if (e.target.name === "end_time") time.end_meridian = e.target.value;
    setTimer(time);
  };

  const renderAllotedSlot = () => {
    let startTime = "";
    let endTime = "";
    let showCustomSlot = showAllotedPremiumSlot;
    let slotId = "";
    if (hasResourceAccess || addBooking) {
      let selected = selectedProcedureList.find(
        (ob) => ob.id === selectedResourceProcedure.id
      );
      if (selected) {
        startTime = selected["alloted_custom_slot"]?.startTime;
        endTime = selected["alloted_custom_slot"]?.endTime;
        showCustomSlot = selected["custom_slot"];
        slotId = selected["alloted_custom_slot"]?.slot_id;
      }
    } else {
      startTime = allotedPremiumSlot?.startTime;
      endTime = allotedPremiumSlot?.endTime;
      slotId = allotedPremiumSlot?.slot_id;
    }
    return (
      showCustomSlot &&
      startTime &&
      endTime && (
        <>
          {startTime && endTime && (
            <>
              <hr className="pss-divider" />
              <div className="pss-selected-slot">
                <div className="premium-slot">
                  <span className="premium-slot-badge">C</span> {startTime} -{" "}
                  {endTime}
                </div>
                <p className="m-0 pss-slot-message">
                  Your appointment time will be {startTime} - {endTime}
                </p>
              </div>
            </>
          )}
        </>
      )
    );
  };

  const renderPremiumslot = () => {
    return (
      <div className="premium-slot-selection-wrapper-box">
        <div className="premium-slot-selection">
          <div className="pss-main-wrapper">
            <div className="pss-slot-apply">
              <p className="pss-slot-apply-enter-slot">
                Enter Slot Time
                <span>
                  <Popover
                    isOpen={openPremiumPopover}
                    preferPlace="below"
                    place={null}
                    className="pop-class"
                    body={
                      <p style={{ fontSize: "14px" }} className="m-0 p-2">
                        Time will vary depending on the next appointment
                      </p>
                    }
                  >
                    <img
                      src={Info}
                      alt=""
                      className="ml-2 pss-info-icon"
                      style={{ width: "16px", height: "16px" }}
                      onMouseOver={() => setOpenPremiumPopover(true)}
                      onMouseOut={() => setOpenPremiumPopover(false)}
                    />
                  </Popover>
                </span>
              </p>
              <div className="pss-start-end-wrapper">
                <div className="pss-start">
                  <DelayTimePicker
                    inputLabel={"Start Time"}
                    timeError={errors?.start_error}
                    hour={timer?.start_hr}
                    min={timer?.start_min}
                    meridiem={timer?.start_meridian}
                    handleHr={handleHr}
                    handleMin={handleMin}
                    handleMeridiem={handleMeridian}
                    doctor_schedule_id={selectedResourceProcedure?.id}
                    name="start_time"
                    error_message={errors?.start_error}
                  />
                </div>
                <div className="pss-end">
                  <DelayTimePicker
                    inputLabel={"End Time"}
                    timeError={errors?.end_error}
                    hour={timer?.end_hr}
                    min={timer?.end_min}
                    meridiem={timer?.end_meridian}
                    handleHr={handleHr}
                    handleMin={handleMin}
                    handleMeridiem={handleMeridian}
                    doctor_schedule_id={selectedResourceProcedure?.id}
                    name="end_time"
                    error_message={errors?.end_error}
                  />
                </div>
              </div>
              <div className="pss-apply-button">
                <button
                  className="btn primary-cta-button pss-apply rounded-pill py-2 px-4"
                  onClick={() =>
                    onApplyingPremiumSlot(selectedResourceProcedure)
                  }
                  disabled={
                    !timer.start_hr ||
                    !timer.end_hr ||
                    !timer.start_min ||
                    !timer.end_min ||
                    errors?.start_error ||
                    errors?.end_error
                  }
                >
                  Apply
                </button>
              </div>
            </div>
            <div style={{ marginTop: "24px" }}></div>
          </div>
        </div>
        {renderAllotedSlot()}
      </div>
    );
  };

  return (
    <div className={"booking-calender-wrapper "}>
      {hideAnnotation ? "" : <h2>BOOK APPOINTMENT</h2>}
      <div
        className={
          !bookingFromFollowUpDashboard &&
          (addBooking && !isAbhaTokenBooking && !isLeadBooking
            ? "date-selector-disable"
            : "")
        }
      >
        <DateSelector
          consultType={consultType}
          followUpEndDate={followUpEndDate}
          onSelect={selectDateHandler}
          selectedDate={selectedDate}
          fromCalender={fromCalender}
          calenderDate={calenderDate}
          track={track}
          selectedDateMeta={selectedDateMeta}
          dashboardSelectedDate={dashboardSelectedDate}
        />
      </div>
      {showPremiumSlotSelection ? (
        renderPremiumslot()
      ) : (
        <SlotsContainer
          alternateNumber={alternateNumber}
          followUpInfo={followUpInfo}
          setConsultData={setConsultData}
          consultType={consultType}
          selectedDate={selectedDate.meta}
          doctorId={doctorId}
          onSelect={onSlotSelect}
          setStartTime={setStartTime}
          className={slotsClass}
          bookFollowUp={bookFollowUp}
          selectedProcedure={selectedProcedure}
          procedureDetails={procedureDetails}
          doctor={doctor}
          slugUrl={slugUrl}
          reschedule={reschedule}
          apiConsultationType={apiConsultationType}
          retainedSlot={retainedSlot}
          retainedSlotObj={retainedSlotObj}
          getSlotTime={getSlotTime}
          handleSlotToEMpty={handleSlotToEMpty}
          handleInstruction={handleInstruction}
          doctorSlug={doctorSlug} //passing doctor slug for patient/doctor reschedule
          procedure_id={procedure_id} //passing procedure id for patient/doctor reschedule
          type={type}
          selectedClinic={selectedClinic}
          clinicID={clinicId} // passing for patient side rescheduling
          setDateSelectEnable={setDateSelectEnable}
          handleDate={handleDate}
          selectedResourceProcedure={selectedResourceProcedure}
          handleSelectResourceSlot={handleSelectResourceSlot}
          hasResourceAccess={hasResourceAccess}
          addBooking={addBooking}
          procedures={procedures}
          booking_type={booking_type}
          isReschedule={isReschedule}
          isConsultant={isConsultant}
          isSchedule={isSchedule}
          setDuration={setDuration}
          isLeadBooking={isLeadBooking}
          followUpBooking={followUpBooking}
        />
      )}
    </div>
  );
};

export default BookSchedule;
