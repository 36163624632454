import React from "react";
import "./customCheckbox.scss";
import Checkmark from "../../../assets/tick.svg";

const CustomCheckbox = (props) => {
  const { title, selected, handleOnSelect } = props;

  return (
    <div className="custom-checkbox-container">
      <div onClick={handleOnSelect} style={{ position: "relative" }}>
        <input
          type="checkbox"
          className="custom-checkbox-box"
          checked={selected}
          readOnly
        />
        <img
          src={Checkmark}
          className={"checkmark" + (selected ? " visible" : " hidden")}
          alt=""
        />
      </div>

      <label className="custom-checkbox-title">{title}</label>
    </div>
  );
};

export default CustomCheckbox;
