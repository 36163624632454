import React from "react";
import { PDFViewer } from "@react-pdf/renderer";
import PDFViewForC2C from "./PDFViewForC2C";

const ERXPdfViewConatiner = (props) => {
  const { erxPdfData, lang, pdfMainContent } = props;

  return (
    <div>
      <div style={{ display: "flex" }}>
        {pdfMainContent?.showPdf && (
          <PDFViewer
            style={{ width: "100%", height: "calc(100vh - 72px)" }}
            showToolbar={false}
          >
            <PDFViewForC2C
              erxPdfData={erxPdfData}
              lang={lang}
              pdfMainContent={pdfMainContent}
            />
          </PDFViewer>
        )}
      </div>
    </div>
  );
};

export default ERXPdfViewConatiner;
