import React from "react";
import "./shareWithPharmacyPopup.scss";
import C2CCustomPopup from "../../../common/Popups/C2CCustomPopup";
import CustomCheckbox from "../../../common/CustomCheckbox";

const ShareWithPharmacyPopup = (props) => {
  const {
    openPopup,
    disableShareBtn,
    pharmacyList,
    handleClosePopup,
    handleSelectPharmacy,
    handleSharePharmacy,
  } = props;

  // Actions ===>

  // <===

  // Display UI ===>

  const displaySharePharmacyPopup = () => {
    return (
      <div>
        <C2CCustomPopup
          dialogClassName={"share-with-pharmacy-popup"}
          openPopup={openPopup}
          popupHeading="Select Other Pharmacy"
          middleBtn="Share"
          middleBtnDisabled={disableShareBtn}
          handleClosePopup={handleClosePopup}
          handleMiddleBtnClick={handleSharePharmacy}
        >
          {displayPharmacyList()}
        </C2CCustomPopup>
      </div>
    );
  };

  const displayPharmacyList = () => {
    return (
      <div>
        {pharmacyList.map((item) => (
          <div>
            <CustomCheckbox
              title={item.name}
              selected={item.selected}
              handleOnSelect={() => handleSelectPharmacy(item)}
            />
          </div>
        ))}
      </div>
    );
  };

  // <===

  return <div>{displaySharePharmacyPopup()}</div>;
};

export default ShareWithPharmacyPopup;
